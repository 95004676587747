<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
<!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-message" class="ml-5" v-model="email"></el-input>-->
<!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-position" class="ml-5" v-model="address"></el-input>-->
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
      <!-- <el-upload action="http://39.106.92.106:9090/mtgroup/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button> -->
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" width="80" label="ID" sortable></el-table-column>
      <el-table-column prop="name" label="组名"></el-table-column>
      <el-table-column label="负责人">
        <template v-slot="scope">
          <span>{{ users.find(v => v.id === scope.row.userId) ? users.find(v => v.id === scope.row.userId).nickname : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="负责区域">
        <template v-slot="scope">
          <span>{{ getLineNames(scope.row.id)  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="组员">
        <template v-slot="scope">
          <span>{{ viewGroupUser(scope.row.id)  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="handleGroup(scope.row.id)">编辑组员 <i class="el-icon-document"></i></el-button>
          <el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
          <el-popconfirm
              class="ml-5"
              confirm-button-text='确定'
              cancel-button-text='我再想想'
              icon="el-icon-info"
              icon-color="red"
              title="您确定删除吗？"
              @confirm="del(scope.row.id)"
          >
            <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="组名">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="负责人">
          <el-select filterable clearable v-model="form.userId" placeholder="负责人">
            <el-option v-for="item in users" :key="item.nickname" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑组员" :visible.sync="dialogFormVisible3" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="小组ID">
            <el-input v-model="form2.groupId" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="组员">
        <el-select v-model="form2.selectedUsers" multiple filterable placeholder="请选择">
          <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.nickname"
              :value="item.id">
          </el-option>
        </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="handleGroupUser">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="小组成员" :visible.sync="dialogFormVisible2" width="30%" :close-on-click-modal="false">
      <el-table :data="groupUsers" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="username" label="用户名" width="140"></el-table-column>
        <el-table-column prop="nickname" label="昵称" width="120"></el-table-column>
        <el-table-column prop="email" label="邮箱"></el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Mtgroup",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      form: {},
      // form2:{},
      form2: {
        selectedUsers: [], // 存储选中的用户ID
        groupId: null,
      },
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      multipleSelection: [],
      users: [],
      lines: [],
      lineGroups: [],
      // selectedUsers: [],
      groupUsers: [],
      baseURLdata: request.defaults.baseURL,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/mtgroup/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.request.get("/user").then(res => {
        this.users = res.data
      });
      this.request.get("/line").then(res => {
        this.lines = res.data
      })
      this.request.get("/lineGroup").then(res => {
        this.lineGroups = res.data
      })
    },
    save() {
      this.request.post("/mtgroup", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    del(id) {
      this.request.delete("/mtgroup/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    getMaintainerIds(groupId) {
      const usergroup = this.users.filter(item => item.groupId === groupId);
      console.log(usergroup.map(item => item.id))
      return usergroup.map(item => item.id);
    },
    handleGroup(groupId) {
      this.form2 = {
        selectedUsers: this.getMaintainerIds(groupId) || [],// 存储选中的用户ID
        groupId: groupId,
      }
      this.dialogFormVisible3 = true;
    },
    handleGroupUser() {
      this.request.post("/user/handGroup", this.form2).then(res => {
        if (res.code === '200') {
          this.$message.success("修改成功")
          this.dialogFormVisible3 = false
          this.load()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    viewGroupUser(groupId) {
      const usergroup = this.users.filter(item => item.groupId === groupId);
      console.log(usergroup.map(item => item.id))
      return usergroup.map(item => {
        const user = this.users.find(u => u.id === item.id);
        return user ? user.nickname : item.id;
      }).join(', ');
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/mtgroup/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    getLineNames(groupId) {
      const groupLines = this.lineGroups.filter(line => line.groupId === groupId);
      return groupLines.map(item => {
        const line = this.lines.find(u => u.id === item.lineId);
        return line ? line.name : '';
      }).join(', ');
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open(this.baseURLdata+"/mtgroup/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
